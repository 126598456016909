import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { TabContainer, TabContent, TabPane, Nav } from 'react-bootstrap'
import { Link } from 'gatsby'

import { Container } from '../../components/Container'
import { Layout } from '../../components/Layout'
import { NewsFeed } from '../../components/NewsFeed'
import { NewsFeedGiovanili } from '../NewsGiovanili'
import { Section } from '../../components/Section'
import { SEO } from '../../components/Seo'
import { Title } from '../../components/Title'

import * as classes from './news.module.scss'

const filters = [
  {
    title: 'Tutte le news',
    key: '',
    Component: <NewsFeed category={'news'} />,
  },
  {
    title: 'Academy Cup',
    key: 'academyCup',
    Component: <NewsFeed category={'academyCup'} />,
  },
  {
    title: 'Giovanili',
    key: 'giovanili',
    Component: <NewsFeedGiovanili />,
  },
  {
    title: 'Sponsor',
    key: 'sponsor',
    Component: <NewsFeed category={'sponsor'} />,
  },
  {
    title: 'Academy',
    key: 'academy',
    Component: <NewsFeed category={'academy'} />,
  },
  {
    title: 'PR News',
    key: 'pr-news',
    Component: <NewsFeed category={'prNews'} />,
  },
]

interface NewsPageProps extends RouteComponentProps {
  '*'?: string
}

const getDefaultActiveKey = (key: string) => {
  const tabExists = filters.some((filter) => filter.key === key)

  if (tabExists) {
    return key
  }

  return filters[0].key
}

export const NewsPage: React.FC<NewsPageProps> = (props) => (
  <Layout>
    <SEO title={'News'} />

    <Container>
      <Section>
        <TabContainer
          id={'news-categories'}
          defaultActiveKey={getDefaultActiveKey(props['*']!)}
        >
          <Nav className={classes.nav}>
            {filters.map(({ title, key }) => (
              <Nav.Item key={key} className={classes.itemContainer}>
                <Nav.Link
                  as={Link}
                  to={`/news/${key}`}
                  eventKey={key}
                  className={classes.navLink}
                >
                  <Title as={'h6'}>{title}</Title>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <TabContent>
            {filters.map(({ key, Component }) => (
              <TabPane eventKey={key} key={key} unmountOnExit mountOnEnter>
                {Component}
              </TabPane>
            ))}
          </TabContent>
        </TabContainer>
      </Section>
    </Container>
  </Layout>
)
