import React from 'react'
import HtmlReactParser, {
  attributesToProps,
  domToReact,
} from 'html-react-parser'
import { DomElement } from 'domhandler'

import { Button } from '../components/Button'
import { Image } from '../components/Image'
import { Title } from '../components/Title'

export type GetAdditionalOptions = (
  element: DomElement,
  options: any
) => React.ReactElement | object | undefined | false

export const parse = (
  text: string,
  getAdditionalOptions?: GetAdditionalOptions
) => {
  const options = {
    replace: ({
      name,
      children,
      attribs,
    }: DomElement): React.ReactElement | object | undefined | false => {
      if (attribs?.class?.includes('wp-block-spacer')) {
        return <div style={{ height: 100 }} />
      }

      if (attribs && Object.prototype.hasOwnProperty.call(attribs, 'style')) {
        return
      }

      if (attribs?.class?.includes('wp-block-button__link')) {
        return (
          <Button {...attribs} as={name}>
            {domToReact(children, options)}
          </Button>
        )
      }

      switch (name) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          return (
            <Title as={name}>{children && domToReact(children, options)}</Title>
          )
        case 'img':
          const { class: className, alt, src, ...props } = attribs!

          return <Image alt={alt} src={src} {...props} className={className} />
        case 'span':
          return <span>{children && domToReact(children, options)}</span>
        default:
          if (getAdditionalOptions) {
            return getAdditionalOptions({ name, children, attribs }, options)
          }

          return undefined
      }
    },
  }

  return HtmlReactParser(text, options)
}
