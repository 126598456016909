import React from 'react'
import { Router } from '@reach/router'

import { Article } from '../../containers/Article'
import { NewsPage } from '../../containers/News'

export default () => (
  <Router>
    <NewsPage path={'/news/*'} />
    <Article path={'/news/:category/:slug'} />
  </Router>
)
