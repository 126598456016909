import React from 'react'
import { connect } from 'react-redux'
import { Image } from 'react-bootstrap'
import { RouteComponentProps } from '@reach/router'

import { AppState } from '../../reducers'
import { ApiArticle } from '../../actions/articles'
import { Article as ArticleInterface } from '../../reducers/articles'
import { getAllArticles } from '../../selectors/articles'

import { parse } from '../../utils/parse'
import { transformApiArticles } from '../../utils/articles'

import {
  Blanket,
  BlanketTitle,
  BlanketContent,
  BlanketBackground,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { SEO } from '../../components/Seo'
import { Spinner } from '../../components/Spinner'

import { api } from '../../utils/api'

import * as classes from './article.module.scss'

interface ArticleProps extends RouteComponentProps {
  slug?: string
  article?: ArticleInterface
}

interface State {
  fetching: boolean
  error: boolean
  article: ArticleInterface | null
}

class _Article extends React.Component<ArticleProps, State> {
  state = {
    fetching: false,
    error: false,
    article: null,
  }

  componentDidMount(): void {
    this.fetchArticle()
  }

  componentDidUpdate(
    prevProps: Readonly<ArticleProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { slug } = this.props

    if (prevProps.slug !== slug) {
      this.setState({
        fetching: false,
        error: false,
        article: null,
      })

      this.fetchArticle()
    }
  }

  fetchArticle = async () => {
    const { article, slug } = this.props

    if (article) {
      return
    }

    this.setState({ fetching: true })

    try {
      const article = await api.get<ApiArticle[]>(`/posts?slug=${slug}`)

      this.setState({ article: transformApiArticles(article[0]) })
    } catch {
      this.setState({ error: true })
    } finally {
      this.setState({ fetching: false })
    }
  }

  getArticle = () => {
    return this.props.article ?? this.state.article
  }

  render() {
    const { fetching } = this.state
    const { slug } = this.props
    const article = this.getArticle()

    return (
      <Blanket className={classes.newsTitle}>
        <SEO title={article?.title ?? slug!} />

        <BlanketBackground>
          <Container size={'medium'}>
            <BlanketTitle>{article ? parse(article.title) : slug}</BlanketTitle>
          </Container>
        </BlanketBackground>

        <BlanketBackground>
          {article?.featuredImage && (
            <Image
              src={article?.featuredImage?.full}
              className={classes.image}
              fluid
            />
          )}
        </BlanketBackground>

        <BlanketBackground>
          <Container size={'small'}>
            <BlanketContent>
              {article && parse(article?.content)}

              <Spinner show={fetching} />
            </BlanketContent>
          </Container>
        </BlanketBackground>
      </Blanket>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: ArticleProps) => ({
  article: getAllArticles(state).find(
    (article) => article.slug === ownProps.slug
  ),
})

export const Article = connect(mapStateToProps)(_Article)
